import React from 'react';

export const trackPageView = url => {
  if (window.ga) {
    const tracker = window.ga.getAll()[0];
    if (tracker && tracker.set && tracker.send) {
      tracker.set('page', url);
      tracker.send('pageview');
    }
  }
};

export const trackEvent = (category, action, label, value) => {
  if (window.ga) {
    const tracker = window.ga.getAll()[0];
    if (tracker && tracker.set && tracker.send) {
      tracker.send('event', category, action, label, value);
    }
  }
};

export const InitAnalytics = props => {
  //return (<script async src={`https://www.googletagmanager.com/gtag/js?id=${props.id}`}>
  return (
    <script>
      {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', ${props.id});
            `}
    </script>
  );
};
