import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Image from 'common/src/components/Image';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import Particles from '../Particle';
import GlideCarousel from 'common/src/components/GlideCarousel';
import GlideSlide from 'common/src/components/GlideCarousel/glideSlide';
import BannerWrapper, {
  DiscountLabel,
  BannerObject,
} from './bannerSection.style';

import BannerObject1 from 'common/src/assets/image/saas/banner/screenshot.png';
import BannerObject2 from 'common/src/assets/image/saas/banner/screenshot2.png';
import { trackEvent } from 'common/src/modules/analytics';

const BannerSection = ({
  row,
  col,
  title,
  btnStyle,
  description,
  discountText,
  discountAmount,
  outlineBtnStyle,
}) => {
  const ButtonGroup = () => (
    <Fragment>
      <a href="#pricing_section">
        <Button
          title="Try for free"
          {...btnStyle}
          onClick={() => {
            trackEvent('try-for-free');
          }}
        />
      </a>
      <a href="mailto:contact@pricemaster.io" target="_blank">
        <Button
          className="outlined"
          title="Contact Us"
          variant="outlined"
          onClick={() => {
            trackEvent('contact-us');
          }}
          {...outlineBtnStyle}
        />
      </a>
    </Fragment>
  );

  const pricingCarouselOptions = {
    type: 'slider',
    perView: 1,
    gap: 30,
    autoplay: 5000,
  };

  return (
    <BannerWrapper id="banner_section">
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <FeatureBlock
              title={
                <Heading
                  content="Show your products cost less than Amazon, eBay and others"
                  {...title}
                />
              }
              description={
                <Text
                  content="We automatically scan your product catalog, and show the customers when your products cost less than competitors. We currently support Amazon, eBay, Walmart and Best Buy."
                  {...description}
                />
              }
              button={<ButtonGroup />}
            />
          </Box>
        </Box>
      </Container>
      <BannerObject>
        <div style={{ width: '100%' }}>
          <GlideCarousel
            carouselSelector="screenshot-carousel"
            options={pricingCarouselOptions}
            controls={false}
          >
            <>
              <GlideSlide center>
                <Image src={BannerObject1} alt="screenshot" />
              </GlideSlide>
              <GlideSlide center>
                <Image src={BannerObject2} alt="screenshot" />
              </GlideSlide>
            </>
          </GlideCarousel>
        </div>
      </BannerObject>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, '70%', '50%', '45%'],
  },
  title: {
    fontSize: ['22px', '34px', '30px', '55px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.5',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '0',
  },
  btnStyle: {
    minWidth: ['120px', '120px', '120px', '156px'],
    fontSize: ['13px', '14px'],
    fontWeight: '500',
    colors: 'primaryWithBg',
  },
  outlineBtnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#5167db',
    ml: '18px',
  },
  discountAmount: {
    fontSize: '14px',
    color: '#eb4d4b',
    mb: 0,
    as: 'span',
    mr: '0.4em',
    fontWeight: 700,
  },
  discountText: {
    fontSize: ['13px', '14px'],
    color: '#0f2137',
    mb: 0,
    as: 'span',
    fontWeight: 500,
  },
};

export default BannerSection;
